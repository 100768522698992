/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    let _ = import(`intersection-observer`);
    console.log(`👍 IntersectionObserver is polyfilled`)
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`);
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    import(`object-fit-images`)();
    console.log(`👍 Object-fit/Object-position are polyfilled`)
  }

  window.scrollTo(0, 0)
};

const scrollTo = (id) => () => {
  const el = document.querySelector(id);
  if (el) return window.scrollTo(0, el.offsetTop - 20);
  return false
};

exports.onRouteUpdate = ({ location: { pathname, search, hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 10)
  }
};